import React, { useEffect, useState } from "react";
import "../../scss/adminBusiness.scss";
import Container from "../../layout/gridContainer";
import Profile from "./business/profile";
import TaxInformation from "./business/taxInformation";
import BusinessManages from "../../api/businessManages";
import { useAuth } from "user-context-fran-dev";
import { GenericAddressDrawer } from "../../components/GenericAddAddress";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Stack,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { GenericDrawer } from "../../components/keskiCreator/genericDrawer";
import SocialMedia from "./business/socialMedia";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LinkIcon from "@mui/icons-material/Link";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";

function MyBusiness() {
  const socialMediaIcon = [
    FacebookIcon,
    InstagramIcon,
    WhatsAppIcon,
    YouTubeIcon,
    LinkedInIcon,
    TwitterIcon,
  ];
  const socialPropPath = [
    "facebook",
    "instagram",
    "whatsapp",
    "youtube",
    "linkedin",
    "tiktok",
  ];

  const [business, setBusiness] = useState("");
  const { logout } = useAuth();
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  const toggleDrawer = (anchor, open) => {
    // console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };

  const MediaInfo = socialMediaIcon.map((icon, index) => ({
    icon: icon,
    value: socialPropPath[index], // Set value to the corresponding key
  }));

  useEffect(() => {
    UpdateBusinessInfo();
  }, []);

  const UpdateBusinessInfo = () => {
    (async () => {
      let useBusines = new BusinessManages();
      let response = await useBusines.getAllAuth(logout);
      console.log("response business:", response?.data);

      setBusiness(response?.data);
    })();
  };

  return (
    <Container>
      <Grid sx={{ m: "24px 0 40px" }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="title-card"
        >
          <strong>Mi negocio</strong>
        </Typography>
      </Grid>
      {business && (
        <Grid
          container
          spacing={4}
          display={"flex"}
          flexDirection={{ xs: "column", sm: "column", md: "row" }}
        >
          <Grid item xs={12} sm={12} md={8}>
            <Profile data={business} UpdateInfo={UpdateBusinessInfo} />
            <TaxInformation data={business} UpdateInfo={UpdateBusinessInfo} />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {" "}
            <Paper elevation={3} sx={{ mb: "24px" }}>
              <Grid
                p={"16px 24px"}
                display={"flex"}
                justifyContent={"space-between"}
                bgcolor={"#f7f7f7"}
              >
                <Typography color="#605D5D">
                  <strong>Dirección</strong>
                </Typography>
                <EditOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => toggleDrawer("right", true)}
                />
              </Grid>
              <Divider />
              <Grid p={"16px 24px"}>
                {business.address ? (
                  <>
                    <Typography fontSize={"18px"}>
                      {business?.address?.street}
                    </Typography>
                    <Typography fontSize={"18px"}>
                      {business?.address?.suburb} C.P{" "}
                      {business?.address?.postal_code}
                    </Typography>
                    <Typography fontSize={"18px"}>
                      {business?.address?.city}, {business?.address?.state}
                    </Typography>
                  </>
                ) : (
                  <Typography align="center">
                    No ahi informacion registrada
                  </Typography>
                )}
              </Grid>
            </Paper>
            <Paper elevation={3} sx={{ mb: "24px" }}>
              <Grid
                p={"16px 24px"}
                display={"flex"}
                justifyContent={"space-between"}
                bgcolor={"#f7f7f7"}
              >
                <Typography color="#605D5D">
                  <strong>Redes sociales</strong>
                </Typography>
                <EditOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => toggleDrawer("right1", true)}
                />
              </Grid>
              <Divider />
              <Grid
                padding={"7px"}
                justifyContent={"space-evenly"}
                sx={{ flexWrap: "wrap" }}
                display={"flex"}
              >
                {MediaInfo?.map((val, index) => {
                  const IconComponent = val.icon;
                  const isActive = business[val.value];
                  return (
                    <Grid p={"16px 0"} key={index}>
                      <a
                        href={isActive ? "https://" + `${isActive}` : false}
                        target="_blank"
                      >
                        <Tooltip title={business[val.value]}>
                          <IconComponent
                            value={val.value}
                            sx={{
                              fontSize: 50,
                              color: isActive ? "#60269E" : "grey",
                              cursor: isActive ? "pointer" : "auto",
                            }}
                          />
                        </Tooltip>
                      </a>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
            <Paper elevation={3} sx={{ mb: 5 }}>
              <Grid
                p={"16px 24px"}
                display={"flex"}
                justifyContent={"space-between"}
                bgcolor={"#f7f7f7"}
                alignItems={"center"}
              >
                <Typography color="#605D5D">
                  <strong>Subscripción</strong>
                </Typography>
                <a
                  href="https://keskinube.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LaunchRoundedIcon sx={{ cursor: "pointer" }} />
                </a>
              </Grid>
              <Divider />
              <Typography>
                <Stack p={"16px 24px"} spacing={1}>
                  <Typography fontSize={"18px"} color="grey">
                    <b>
                      Business{" "}
                      {business?.address?.business?.is_active === 1
                        ? "Plus"
                        : "Standard"}
                    </b>
                  </Typography>
                  <Typography
                    color={
                      business?.address?.business?.is_active ? "green" : "red"
                    }
                    fontSize={"18px"}
                  >
                    <strong>
                      {business?.address?.business.is_active
                        ? "Activo"
                        : "Inactivo"}
                    </strong>
                  </Typography>

                  <Typography fontSize={"18px"}>
                    Desde:{" "}
                    {DateFormatter(business?.address?.business.date_created)}
                  </Typography>
                </Stack>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
      <GenericAddressDrawer
        openDrawer={openDrawer}
        toggleDrawer={toggleDrawer}
        data={business}
        UpdateInfo={UpdateBusinessInfo}
        target={"business"}
        close={() => toggleDrawer("right", false)}
      />
      <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor={"right1"}
        drawerWidth="30%"
      >
        <Box>
          <Grid
            p={"16px"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontSize={"20px"} p={"5px 0"}>
              <b>Redes Sociales</b>
            </Typography>

            <IconButton onClick={() => toggleDrawer("right1", false)}>
              <CloseSharpIcon fontSize="small" />
            </IconButton>
          </Grid>

          <SocialMedia data={business} UpdateInfo={UpdateBusinessInfo} />
        </Box>
      </GenericDrawer>
    </Container>
  );
}

export default MyBusiness;

export function DateFormatter(isoDate) {
  if (!isoDate) return "Fecha no disponible";
  const date = new Date(isoDate);
  if (isNaN(date)) return "Fecha no válida";
  return format(date, "dd 'de' MMMM 'de' yyyy", { locale: es });
}
