import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { useFormik } from "formik";
import { Grid, TextField } from "@mui/material";
import { useAuth } from "user-context-fran-dev";
import { ProductService } from "../../api/productService";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CategoryManages from "../../api/categoryManages";
import { Suppliers } from "../../api/supplierService";
import { PaginationGeneric } from "react-generic-pagination";
import DrawerArticle from "./DrawerArticle";
import GenericTable from "../../components/GenericTable";
import LoadingButton from "@mui/lab/LoadingButton";
import { initSearchParameters } from "../../utils/formHelper";
import { Row } from "./ArticleExpandableView";
import { useEffect, useState } from "react";
import { GenericEmptyTable } from "../GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";

export function ArticleTableSearcher(props) {
  const { type, reloadDataOforder, wh } = props;
  const products = new ProductService();
  const { logout } = useAuth();
  const [totalpages, setTotalPages] = useState("");
  const [filters, setFilters] = useState({ category: "", supplier: "" });
  const [selectedOption, setSelectedOption] = useState("pattern");
  const [key, setKey] = useState("pattern");
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = 10;
  const [category, setCategory] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [dataPresentation, setDataPresentation] = useState(false);
  const [handelError, setHandelError] = useState(false);
  const [dataProduct, setDataProduct] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTable, setData] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });

  useEffect(() => {
    GetAllProducts(currentPage);
    Category();
    SuppliersList();
  }, [currentPage]);

  //-------Get Methods-------------------------------->
  const Category = () => {
    (async () => {
      const getList = new CategoryManages();
      const response = await getList.getAllAuth(logout);
      setCategory(response.data);
    })();
  };

  const SuppliersList = () => {
    (async () => {
      let Temp = [];
      const getList = new Suppliers();
      const response = await getList.getAllAuth(logout);

      if (Array.isArray(response?.data)) {
        Temp.push({ label: "Todo", id: "" });
        response.data.forEach((element) => {
          Temp.push({ label: element.business_name, id: element.id });
        });
      } else {
        console.error('Expected an array, but got:', response?.data);
      }

      setSupplier(Temp);
    })();
  };

  const GetAllProducts = async (page) => {
    const irequestFilter = [
      { key: "page", value: page.toString() },
      {
        key: "items",
        value: `${totalItems}`,
      },
    ];

    try {
      let response = await products.filter(irequestFilter, logout);
      console.log("products", response);
      setAllProduct(response?.data.data);
      setTotalPages(response?.data.pagination);
    } catch (error) {
      console.error("Error al obtener productos:", error);
    }
  };
  //---------------- toggleDrawer ------------------------------->
  const toggleDrawer = (anchor, open) => {
    // console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };
  //-------Handel Change Methods ---------------------------------------->
  const handleSelectChange = (e, val) => {
    const selectedValue = e.target.value; // Get selected value
    const selectedItem =
      val.filter === "supplier"
        ? val.options?.find((option) => option.id === selectedValue)
        : undefined;

    setChangesList(e, selectedItem);
    //console.log(selectedItem);
  };

  const setChangesList = (e, newValue) => {
    if (newValue) {
      // Si estamos cambiando el valor del proveedor, aplicamos solo este filtro.
      setFilters({ ...filters, supplier: newValue.id });
      formik.setFieldValue("supplier", newValue.id);
      return;
    }

    if (e.target.name === "category") {
      setFilters({ category: e.target.value, supplier: "" });
      formik.setFieldValue("category", e.target.value);
    } else {
      setFilters({ ...filters, [e.target.name]: e.target.value });
      formik.setFieldValue(e.target.name, e.target.value);
    }

    // console.log(filters);
  };

  const pageSetter = (page) => {
    setCurrentPage(page);
    /* navigate(`page=${page}`) */
  };

  //------Form validation Methods --------------------------------->
  const formik = useFormik({
    initialValues: initSearchParameters(),
    validationSchema: false,
    onSubmit: async (formData) => {
      console.log("Filters at submit:", filters);
      setLoading(true);
      setResetForm(false);
      setHandelError(false);
      setData("");

      const irequestFilter = [];

      if (formik.values.category) {
        irequestFilter.push({ key: "category", value: formik.values.category });
      }
      if (formik.values.supplier) {
        irequestFilter.push({ key: "supplier", value: formik.values.supplier });
      }
      console.log("Constructed irequestFilter:", irequestFilter);
      if (formik.values.name) {
        irequestFilter.push(
          { key: "is_active", value: "true" },
          { key: key, value: formik.values.name },
          { key: "page", value: "1" },
          { key: "items", value: "2" }
        );
      }
      try {
        const response = await products.filter(irequestFilter, logout);

        if (response) {
          console.log("producto", response.data);
          setData(response?.data);
          setDataPresentation(response?.data);
        }
        if (response.status === 204) {
          setHandelError(true);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setHandelError(true);
      } finally {
        setLoading(false);
      }
    },
  });
  //--------- Check Box Methods-------------------->
  const labels = [
    "Por nombre",
    "Por modelo",
    "Por SKU",
    "Por  número  de serie",
  ];
  const keys = ["pattern", "model_name", "sku", "barcode"];

  const CheckboxOption = labels.map((label, index) => ({
    label: label,
    value: keys[index], // Set value to the corresponding key
  }));

  const handleCheckBox = (option) => {
    setSelectedOption(option === selectedOption ? "pattern" : option);
    setKey(option);
  };

  const FormData = [
    {
      filter: "category",
      label: "Categoría",
      options: category || [], // Using category array directly
    },
    {
      filter: "supplier",
      label: "Proveedor",
      options: supplier || [], // Using supplier array directly
    },
  ];
  //------------- products tabele---------------------------------->
  const renderRows = (data) => {
    return data?.map((row, index) => (
      <Row
        key={index}
        row={row}
        setOpenModal={setOpenModal}
        setDataProduct={setDataProduct}
        dataProduct={dataProduct}
        type={type}
        wh={wh}
        display={handelError}
        toggleDrawer={() => toggleDrawer("right1", true)}
      />
    ));
  };
  return (
    <>
      <Box
        component={"form"}
        onSubmit={formik.handleSubmit}
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        mb={1}
      >
        <Grid display={"flex"} flexDirection={"column"}>
          <Stack direction={"row"}>
            <TextField
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                key ? formik.touched.name && Boolean(formik.errors.name) : ""
              }
              label="Buscar producto"
              size="small"
              variant="outlined"
              sx={{ mr: "8px", width: "60%" }}
              disabled={!key}
            />
            {FormData.map((val) => (
              <TextField
                key={val.label}
                label={val.label}
                variant="outlined"
                select
                size="small"
                sx={{ width: "154px", m: "0 8px" }}
                name={val.filter} // Use filter key as name
                value={formik.values[val.filter]}
                onChange={(e) => {
                  val.filter === "supplier"
                    ? handleSelectChange(e, val)
                    : setChangesList(e);
                }}
              >
                {val.options.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {val.filter === "supplier" ? data.label : data.name}
                  </MenuItem>
                ))}
              </TextField>
            ))}
          </Stack>
          <Grid
            container
            display="flex"
            wrap="wrap"
            justifyContent="flex-start"
          >
            {CheckboxOption.map((val, index) => (
              <Grid item key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => handleCheckBox(val.value)}
                      checked={selectedOption === val.value}
                    />
                  }
                  label={val.label}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Stack direction={"row"} height={"40px"}>
          <IconButton
            sx={{ m: { xs: "0", md: "8px 0 8px 16px" }, p: "0" }}
            onClick={() => {
              formik.resetForm();
              setHandelError(false);
              setResetForm(true);
            }}
          >
            <DeleteOutlineOutlinedIcon
              sx={{
                color: "GrayText",
                width: "30px",
                height: "30px",
              }}
            />
          </IconButton>
          <LoadingButton
            loading={loading}
            sx={{ width: "124px", m: " 0 16px", borderRadius: "60px" }}
            type="submit"
            variant="outlined"
          >
            Buscar
          </LoadingButton>
        </Stack>
      </Box>

      <Grid direction="row" justifyContent="space-around" alignItems="center">
        <GenericTable
          titles={
            wh || reloadDataOforder
              ? ["Nombre", "SKU", "Estatus", "Categoría", "Modelos"]
              : ["Imagen", "Nombre", "SKU", "Estatus", "Categoría", "Tipo"]
          }
        >
          <>
            {dataTable && !resetForm ? (
              renderRows(dataTable) // Render rows from dataTable
            ) : !handelError && allProduct ? (
              renderRows(allProduct)
            ) : handelError ? (
              <tr>
                <th colspan="5" scope="colgroup">
                  <Grid
                    sx={{
                      height: "20vh",
                      display: "flex",
                      justifyContent: "center", // Centrado horizontal
                      alignItems: "center", // Centrado vertical
                    }}
                  >
                    <Typography p={"24px"}>
                      No se encuentran resultados
                    </Typography>
                  </Grid>
                </th>
              </tr>
            ) : (
              <GenericEmptyTable />
            )}
          </>
        </GenericTable>

        <Grid mt={"16px"}>
          <PaginationGeneric
            className="paginationStyle"
            pagina={1}
            total={totalpages}
            pageSetter={pageSetter}
          />
        </Grid>
      </Grid>
      <DrawerArticle
        openDrawer={openDrawer}
        type={type}
        toggleDrawer={toggleDrawer}
        reloadDataOforder={reloadDataOforder}
        dataProduct={dataProduct}
        wh={1}
        close={() => toggleDrawer("right1", false)}
      />
    </>
  );
}
