import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import BasicTable from "../../components/GenericTable";
import Container from "../../layout/gridContainer";
import PurchasesTable from "./purchase/purchasesTable";
import AddPurchase from "./purchase/addPurchase";
import PurchaseService from "../../api/purchaseService";
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import GenericSearch from "../../components/GenericSearch/GenericSearch";
import useNexOrder from "../../hooks/nextOrder";
import { useAuth } from "user-context-fran-dev";
import MuiContainer from "@mui/material/Container";
import { tab } from "@testing-library/user-event/dist/cjs/convenience/tab.js";

const InventoryPurchase = () => {
  const [openModal, setOpenModal] = useState();
  const [noItems, setNoItems] = useState(false);
  const [openDrawer, setOpenDrawer] = useState({});
  const [table, setTable] = useState(null);
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const [idOrder] = useNexOrder(table);
  const { logout } = useAuth();
  const totalPerPage = 10;

  const navigate = useNavigate();

  useEffect(() => {
    navigate(`page=${page}`);
    UpdatePurchaseList();
  }, [page]);

  const UpdatePurchaseList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getPurchases = new PurchaseService();
      let response = await getPurchases.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response?.data?.data);
      console.log("response oreders list ", response);
      if (response.status != 200) {
        setNoItems(true);
      }
      setLoading(false);
    })();
  };

  const pageSetter = (page) => {
    setPage(page);
    /* navigate(`page=${page}`) */
  };

  return (
    <Container>

      <Grid
        container
        display={'flex'}
        alignItems="center"
        justifyContent={"space-between"}
        m={'24px 0'} >
        <Typography
          variant="h5"
          component="div"
        >
          <strong>Compras</strong>
        </Typography>
        <Grid item xs={2} >
          <Button
            onClick={() => setOpenDrawer({ right: true })}
            fullWidth
            variant="contained"
            sx={{ borderRadius: 60 }}
          >
            {" "}
            Agregar compra{" "}
          </Button>
          {idOrder && (
            <AddPurchase
              open={openDrawer}
              toggleDrawer={() => { }}
              close={() => setOpenDrawer({ right: false })}
              UpdatePurchaseList={UpdatePurchaseList}
              idOrder={idOrder}
            />
          )}
        </Grid>
      </Grid>
      <GenericSearch
        Service={PurchaseService}
        setData={setTable}
        setLoading={setLoading}
        updateInfo={UpdatePurchaseList}
        label={"Buscar por numero de orden"}
      />
      <Grid item xs={12}>
        <>
          <BasicTable
            className="marginTable"
            titles={[
              "Num. Orden / Fecha",
              "Proveedor/Almacén",
              "Tipo de Orden",
              "Estatus",
              "Total",
              table ? "Más" : "",
            ]}
          >
            {loading && <GenericSkeletonTable />}
            {!loading && !table && <GenericEmptyTable />}
            {table &&
              table?.map((data, index) => (
                <PurchasesTable key={index} data={data} />
              ))}
          </BasicTable>
        </>

        <div className="margin">
          <PaginationGeneric
            className="paginationStyle"
            pagina={1}
            total={totalpages}
            pageSetter={pageSetter}
          />
        </div>
      </Grid>
    </Container>
  );
};

export default InventoryPurchase;
