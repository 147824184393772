import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { TextField, FormHelperText } from "@mui/material";
import BasicModal from "../../../components/GenericModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { initValAddArticle, valSchAddArticle } from "../../../utils/formHelper";
import CategoryManages from "../../../api/categoryManages";
import Products from "../../../api/productsManages";
import { useAuth } from "user-context-fran-dev";
import CountWords from "../../../components/CountWords";

import { useNavigate } from "react-router-dom";
import { GenericEmptyTable } from "../../../components/GenericEmptyTable";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../hooks/snackBarResponse";
import { GenericDrawer } from "../../../components/keskiCreator/genericDrawer";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Card } from "../articleDetail/articleData";
import { ArticleCategory } from "../articleDetail/components/zindex";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
const scroll = {
  overflowY: "scroll",
  maxHeight: "300px",
  scrollbarWidth: "thin",
};

const AddArticle = ({ udateProducts }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const { logout } = useAuth();
  const [msg, sendNotification] = useNotification();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState({
    top: false,
    top1: false,
    right: false,
    right1: false,
  });
  const formRef = useRef(null);

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [open]);

  const getCategory = () => {
    (async () => {
      let getCategory = new CategoryManages();
      let response = await getCategory.getAllAuth(logout);
      setCategory(response.data);
    })();
  };

  const toggleDrawer = (anchor, open) => {
    console.log("Toggling drawer", anchor, open);
    setOpenDrawer((prevState) => ({
      ...prevState,
      [anchor]: open, // Esto actualizará solo la clave correspondiente
    }));
  };


  const formik = useFormik({
    initialValues: initValAddArticle(),
    enableReinitialize: true,
    validationSchema: false,
    onSubmit: async (formData, { resetForm }) => {
      setLoading(true);
      if (!categoryId) {
        console.error("No se ha seleccionado una categoría");
        setLoading(false);
        return;
      }
      formik.values.category = categoryId; // Asegúrate de que `categoryId` esté definido
      let setProduct = new Products();
      try {
        let response = await setProduct.postDataAuth(formData, logout);
        sendNotification(UseerrorOrsuccess(response));
        window.localStorage.setItem("menu", 10);
        setOpen(false);
        navigate(`detail/${response.data.id}`);
      } catch (error) {
        console.error("Error al enviar los datos", error);
      } finally {
        setLoading(false);
        resetForm();
      }
    },
  });

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          toggleDrawer("right1", true);
        }}
        sx={{ height: "30px", borderRadius: "60px" }}
      >
        Agregar
      </Button>
      <GenericDrawer
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        anchor={"right1"}
        drawerWidth="30%"
      >
        <Box
          p={"16px"}
          component={"form"}
          ref={formRef} // Referencia al formulario
          onSubmit={(e) => {
            e.preventDefault(); // Prevenir recarga
            formik.handleSubmit(e); // Llama al envío de Formik
          }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: "16px",
            }}
          >
            <Typography fontSize={"20px"} p={"8px 0"}>
              <b>Agregar producto</b>
            </Typography>

            <IconButton onClick={() => toggleDrawer("right1", false)}>
              <CloseSharpIcon fontSize="small" />
            </IconButton>
          </Grid>
          <Typography>Datos del producto (e.g., Playera)</Typography>
          <Grid mb={'16px'}>
            <TextField
              fullWidth
              name="name"
              values={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              label="Nombre del producto"
              inputProps={{ maxLength: 100 }}
              margin="dense"
              size="small"
              variant="outlined"
              autoComplete="none"
              helperText={formik.touched.name ? formik.errors.name : ""}
            />
            <CountWords
              length={formik.values.name.length.toString()}
              max="100"
            />

          </Grid>
        </Box>
        <Grid p={'0 16px'}>
          <Card p="8px 16px" title={"Categorías"} style={scroll}>
            <ArticleCategory
              setCategoryId={setCategoryId}
              logout={logout}
            />
          </Card>
        </Grid>
        <Grid display="flex" justifyContent={"flex-end"} mt={2} p={"16px"}>
          <LoadingButton
            loading={loading}
            variant="contained"
            sx={{ borderRadius: "60px", width: "150px" }}
            onClick={() => {
              if (formRef.current) {
                formRef.current.requestSubmit(); // Envía el formulario desde el botón externo
              }
            }}
          >
            Agregar
          </LoadingButton>
        </Grid>

      </GenericDrawer>
    </>
  );
};

export default AddArticle;