import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { InventoryServiceInventory } from "../../../api/inventoryService";
import InventoryTable from "./inventoryTable";
import CircularProgress from "@mui/material/CircularProgress";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../../components/GenericEmptyTable";
import { GenericSearchNew } from "../../../components/GenericSearch/GenericSearch";
import { GenericSelectTableNew } from "../../../components/GenericSelectTable";
import WarehouseService from "../../../api/warehouseService";
import { useTableFilters } from "../../../hooks/searchParams";
import PageParam from "../../../components/PageParam";
import BasicTable from "../../../components/GenericTable";

const InventoryTab = () => {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);
  const [params, setParams, handleSearch, handleInputChange, UdateTable, handleChange] =
    useTableFilters(
      InventoryServiceInventory,
      setTable,
      setLoading,
      null,
      "tab",
      500
    );



  return (
    <Box>
      <Grid m={'24px 0'} >
        <h2>Inventario</h2>
      </Grid>
      <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} alignItems={{ xs: '-moz-initial', md: 'center' }}>
        <GenericSearchNew
          params={params}
          setParams={setParams}
          handleChanges={handleInputChange}
          handleSearch={handleSearch}
          name="pattern"
          label={"Buscar por nombre"}
          width={'45%'}
        />
        <GenericSelectTableNew
          text="Seleccionar almacén"
          listService={WarehouseService}
          params={params}
          setChangesList={handleSearch}
          name="wh"
        />
      </Stack>

      {!table ? (
        <Grid sx={{ p: "5px", width: "100%" }}>
          <BasicTable
            titles={[
              "Nombre ",
              "Modelo",
              "Ord.",
              "Dis.",
              "Apart." /* , "Ver" */,
            ]}
          >
            {loading && !table && <GenericSkeletonTable />}
            {!loading && !table && <GenericEmptyTable />}
          </BasicTable>
        </Grid>
      ) : (
        <>
          <Grid >
            <InventoryTable table={table.data} params={params} />
          </Grid>

        </>
      )}
      <Grid item xs={12} mt={2}>
        <PageParam
          setParams={setParams}
          totalpages={table?.pagination || 1}
          params={params}
          handleChange={handleChange}
        />
      </Grid>
    </Box>
  );
};

export default InventoryTab;
