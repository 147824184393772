import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Box,
  Switch,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import { initValAddPresentation } from "../../../../utils/formHelper";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import Presentation from "../../../../api/catalogPresentation";
import { useParams } from "react-router-dom";
import { State as ArticleState } from "./articleState";

export function PriceForm(product) {
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);
  const [modified, setModified] = useState(false);
  const [presentation, setPresentation] = useState();
  const [presentationId, setPresentationId] = useState();
  const prop_path = ["cost", "price", "barcode", "discount"];
  const { id } = useParams();
  const modelId = product.modelId;

  useEffect(() => {

    if (product.variantId) {
      getPresentationService();
    }

  }, [product.setPresentation, product.article, product.variantId]);

  const getPresentationService = async () => {
    try {

      const service = new Presentation();
      const response = await service.getAllByIdAuth(
        product.variantId,
        product.logout
      );
      if (product.addPresentation === "add") {
        setPresentation('');
      } else {
        setPresentation(response.data);
      }

    } catch (error) {
      console.error("Failed to fetch presentation data:", error);
    }
  };


  const formik = useFormik({
    initialValues: initValAddPresentation(presentation, modelId, id, product.type),
    enableReinitialize: true,
    validationSchema: false,
    onSubmit: async (formData) => {
      setLoading(true);
      try {
        const service = new Presentation();
        let response =
          product.presentation === null || product.presentation === undefined || product.addPresentation === 'add'
            ? await service.postDataAuth(formData, product.logout)
            : await service.editDataAuth(product.variantId, formData, product.logout)

        if (response) {
          sendNotification(UseerrorOrsuccess(response));
          product.getArticleInfo();
        } else {
          sendNotification(
            UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
          );
        }
      } catch (error) {
        sendNotification(
          UseerrorOrsuccess({ msg: "Error al actualizar", variant: "error" })
        );
      } finally {
        setLoading(false);
        setModified(false);
        product.setaddPresentation('')
        formik.resetForm();

      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} onChange={() => {
      setModified(true);
    }} >
      <Grid
        display={product.addPresentation === "add" || product.presentation === null && product.type === 3 ? "flex" : "none"}
        justifyContent={'space-between'}
        alignItems={"center"}
        mt={'8px'} >
        <div
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography fontSize={"18px"}>
            <b>Variante</b>
          </Typography>
          <TextField
            required
            margin="normal"
            size="small"
            name="name"
            label={"Agregar variante"}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            sx={{ m: "0 16px" }}
          />
        </div>
        <ArticleState
          disabled={true}
        />
      </Grid>
      <Grid container display={'flex'} justifyContent={'space-between'} >
        {["Costo", "Precio", "Código de barras", "Descuento"].map(
          (val, index) => (
            <>
              <Grid
                item
                xs={6}
                md={6.01}
                sm={12}
                key={index}
                direction="row"
                sx={{ display: val === "Descuento" ? 'flex' : "none", alignItems: "center", p: '0 9px' }}
              >
                <Typography>Oferta</Typography>
                <Switch
                  checked={formik.values.is_discount}
                  onChange={(e) => formik.setFieldValue("is_discount", e.target.checked)}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={val === "Código de barras" ? 6.01 : 6}
                sm={12}
                key={index}
                display={'flex'}
                justifyContent={'center'}
              >

                <TextField
                  name={prop_path[index]}
                  required={val === "Costo" || val === "Precio"}
                  label={val}
                  margin='normal'
                  size="small"
                  value={formik.values[prop_path[index]]}
                  onChange={formik.handleChange}
                  error={formik.errors[prop_path[index]]}
                  sx={{ bgcolor: 'white', width: '95%' }}
                  inputProps={val === "Descuento"
                    ? { maxLength: 99 }
                    : {}}
                  slotProps={{
                    input: {
                      endAdornment: val === "Descuento" ? (
                        <InputAdornment position="end">%</InputAdornment>
                      ) : (
                        <></>
                      ),
                      startAdornment: val !== "Código de barras" && val !== "Descuento" ? (
                        <InputAdornment position="start">$</InputAdornment>
                      ) : (
                        <></>
                      ),
                    },
                  }} />
              </Grid></>
          )
        )}
      </Grid>
      <Box display={"flex"} flexDirection={"column"}>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
            p: "16px 0",
          }}
        >
          <Typography>El descuento aplica:</Typography>
          {[
            { label: "Tienda en línea / Catálogo", field: "discount_pos" },
            { label: "Punto de venta", field: "discount_store" },
          ].map((item, index) => (
            <Stack key={index} direction="row" alignItems={"center"} spacing={-0.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!formik.values.is_discount}
                    onChange={(e) =>
                      formik.setFieldValue(item.field, e.target.checked)
                    }
                    color="primary"
                    checked={formik.values[item.field] || false}
                  />
                }
              />
              <Typography>{item.label}</Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
      <Grid display={"flex"} justifyContent={"flex-end"} mt={'16px'}>

        <LoadingButton
          variant={"outlined"}
          onClick={() => { product.setaddPresentation(" ") }}
          sx={{
            borderRadius: 60,
            mr: "8px",
            display: product.addPresentation === "add" ? " " : "none"
          }}
        >
          Cancelar
        </LoadingButton>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={loading}
          sx={{ borderRadius: 60 }}
          disabled={!modified}
        >
          Guardar
        </LoadingButton>
      </Grid>
    </form>
  );
}