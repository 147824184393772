import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import BasicTable from "../../components/GenericTable";
import Container from "../../layout/gridContainer";
import SalesTable from "./sales/salesTable";
import AddSale from "./sales/addSale";
import SalesService from "../../api/salesService";
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import GenericSearch from "../../components/GenericSearch/GenericSearch";
import useNexOrder from "../../hooks/nextOrder";
import { useAuth } from "user-context-fran-dev";

const InventorySales = () => {
  const [openModal, setOpenModal] = useState();
  const [table, setTable] = useState(null);
  const [openDrawer, setOpenDrawer] = useState({});
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const totalPerPage = 10;
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [idOrder] = useNexOrder(table);

  useEffect(() => {
    navigate(`page=${page}`);
    UpdateSalesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const UpdateSalesList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getSales = new SalesService();
      let response = await getSales.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      setLoading(false);
    })();
  };

  const pageSetter = (page) => {
    setPage(page);
    /* navigate(`page=${page}`) */
  };

  return (
    <Container>

      {/*       <Grid item xs={12}>
          <Typography variant="h5" gutterBottom component="div">
            <strong>Proveedores </strong>
          </Typography>
        </Grid> */}
      <Grid container display={'flex'} alignItems="center" justifyContent={"space-between"} m={'24px 0'}>
        <Typography
          variant="h5"
          component="div"

        >
          <strong>Ventas</strong>
        </Typography>
        <Grid item xs={2} >
          <Button
            onClick={() => setOpenDrawer({ right: true })}
            fullWidth
            variant="contained"
            sx={{ borderRadius: 60 }}
          >
            {" "}
            Agregar Venta
          </Button>
          {idOrder && (
            <AddSale
              UpdateSalesList={UpdateSalesList}
              open={openDrawer}
              toggleDrawer={() => { }}
              close={() => setOpenDrawer({ right: false })}
              idOrder={idOrder}
            />
          )}
        </Grid>
      </Grid>
      <GenericSearch
        Service={SalesService}
        setData={setTable}
        updateInfo={UpdateSalesList}
        setLoading={setLoading}
        label={"Buscar por numero de orden"}
      />
      <Grid item xs={12}>
        <>
          <BasicTable
            className="marginTable"
            titles={[
              "Num. orden / Fecha",
              "Cliente / Almacén ",
              "Estatus",
              "Total",
              "Más",
            ]}
          >
            {loading && <GenericSkeletonTable />}
            {!loading && !table && <GenericEmptyTable />}
            {!loading &&
              table &&
              table.map((data, index) => (
                <SalesTable key={index} data={data}></SalesTable>
              ))}
          </BasicTable>
          <div className="margin">
            <PaginationGeneric
              className="paginationStyle"
              page={1}
              total={totalpages}
              pageSetter={pageSetter}
            ></PaginationGeneric>
          </div>
        </>
      </Grid>

    </Container>
  );
};

export default InventorySales;
