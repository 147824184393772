import React, { useState } from "react";
import { Avatar, Button, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { useAuth } from "user-context-fran-dev";
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { Card } from '../../pages/catalog/articleDetail/articleData'
import DeleteIcon from '@mui/icons-material/Delete';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddContact from './addContact'
import DropMenu from '../../components/dropMenu'
import CurrencyFormat from 'react-currency-format';
import ContactService from "../../api/contactService";
import EditContact from "./editModals/editContact";

const Phonebook = ({ data, UpdateInfo, target }) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false)
  const [loading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [idToEdit, setIdToEdit] = useState('');


  return (
    <>
      <Card title='Contacto'>
        <Grid display={'flex'}
          flexDirection={'column'}
          sx={{ maxHeight: '250px', overflowY: 'scroll', scrollbarWidth: "thin" }}>
          {data.contacts?.map((data, index) => (
            <Grid display={'flex'} key={index} p={'8px 0'} >
              <Avatar sx={{ mr: '16px' }}>{data.name.charAt(0).toUpperCase()}</Avatar>
              <div style={{ flex: 1, minWidth: 0 }}>
                <Stack direction={'column'}  >
                  <p style={{
                    fontSize: '18px',
                    marginRight: '16px',
                    display: 'block',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    maxWidth: '100%',

                  }}>
                    {data.name}
                  </p>
                  <p
                    style={{
                      display: 'block',
                      flexWrap: 'wrap',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      fontSize: '17px',

                    }}
                  >{data.phone ?? " "}</p>
                </Stack>
                <Tooltip title={data.email}>
                  <p
                    style={{
                      width: data.email.length > 33 ? "250px" : "auto",
                      display: 'block',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: data.email.length > 33 ? 'nowrap' : 'normal',
                      margin: 0,
                      maxWidth: '100%',
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >{data.email}
                  </p>
                </Tooltip>
              </div>
            </Grid>
          ))}
        </Grid>
        <AddContact data={data} UpdateInfo={UpdateInfo} business={true} target={target} />
        {/* <EditContact 
          data={idToEdit} 
          openEdit={openEdit} 
          setOpenEdit={setOpenEdit} 
          UpdateInfo={UpdateInfo} 
          business={true} 
          target={target}
           /> */}
      </Card>
    </>

  );
}

export default Phonebook;