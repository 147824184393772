import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Link, Paper } from "@mui/material";
import InventoryGenericDetailsView from "../../../../components/inventario/InventoryGenericDetailsView";
import { useParams } from "react-router-dom";
import PurchaseService from "../../../../api/purchaseService";
import { useAuth } from "user-context-fran-dev";
import ArticlesListScrap from "../../../../components/inventario/tables/ArticlesListScrap";
import HeaderGeneric from "../../../../components/inventario/HeaderGeneric";
import { ArticleExpandableView } from "../../../../components/inventario/ArticleExpandableView";
import { STATUS } from "../../../../utils/constants";
import {
  UseerrorOrsuccess,
  useNotification,
} from "../../../../hooks/snackBarResponse";
import {
  TransferService,
  TransferItemsService,
  TrnsferStatusService,
} from "../../../../api/inventory/transfersService";
import SearchAddTransferArticle from "./addTransferArticle/searchAddTransferArticle";
import {
  STATUSTRANSFER,
  STATUSTRANSFERCOLOR,
} from "../../../../utils/constants";
import ArticlesListTrasnfer from "../../../../components/inventario/tables/ArticleListTransfer";

const TransferDetails = ({ setReload }) => {
  const { id } = useParams();
  const { logout, auth } = useAuth();
  const [data, setData] = useState();
  const [reload, setReloadPage] = useState(false);
  const getTransfers = new TransferService();
  const transferStatusServiceConfirm = new TrnsferStatusService('transfer_order/' + id + '/confirm')
  const transferStatusServiceCancel = new TrnsferStatusService('transfer_order/' + id + '/cancel')
  const [msg, sendNotification] = useNotification();
  const [loading, setLoading] = useState(false);

  const reloadDataOforder = () => {
    (async () => {
      let response = await getTransfers.getAllByIdAuth(id, logout);
      setData(response.data);
      setReload(true);
      setLoading(false);
    })();
  };

  useEffect(() => {
    (async () => {
      let response = await getTransfers.getAllByIdAuth(id, logout);
      window.localStorage.setItem("menu", 24);
      setData(response.data);
      console.log("datos de orden", response.data);
    })();
    return () => {
      setReload((prev) => !prev);
      window.localStorage.setItem("menu", 5);
    };
  }, [reload]);

  const cancel = () => {
    (async () => {
      setLoading(true);
      let response = await transferStatusServiceCancel.editDataAuthNid(null,{},logout);
      console.log(response.data);
      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
    })();
  };

  const finishOrder = () => {
    (async () => {
      setLoading(true);
      let response = await transferStatusServiceConfirm.editDataAuthNid(null,{},logout);
      console.log(response.data);
      sendNotification(UseerrorOrsuccess(response));
      reloadDataOforder();
    })();
  };

  return (
    <>
      {data && (
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={11} className="details-view-header">
            <HeaderGeneric
              leftTitle={{
                status: STATUSTRANSFER[data.status],
                color: STATUSTRANSFERCOLOR[data.status],
              }} //titulo del header izquierdo
              cancelButton={{ handleClick: cancel, loading: loading }} //el boton de cancelar el cual solo se le pasa una funcion pero el titulo siempre es cancelar
              titleButton={{
                tittle: "Finalizar",
                enable: data.articles ? true : false,
              }} // el boton a lado derecho de cancelar este solo es para el titulo
              handleButton={{ handleClick: finishOrder, loading: loading }} //la funcion del boton
              rightTitle="Orden de transferencia"
              name={`${data.user.first_name} ${data.user.last_name}`}
              noOrder={data}
              date={data.date_created}
              /* general='' */
              reloadDataOforder={reloadDataOforder}
              transfer={data}
            ></HeaderGeneric>
          </Grid>

          {data.status == 1 && (
            <Grid item xs={11} className="article-expandable-view">
              <SearchAddTransferArticle
                type="transfer_order"
                reloadDataOforder={reloadDataOforder}
                dataTransfer={data}
              ></SearchAddTransferArticle>
            </Grid>
          )}
          <Grid item xs={11} className="details-view">
            <ArticlesListTrasnfer
              data={data}
              service={TransferItemsService}
              updateInfo={reloadDataOforder}
            ></ArticlesListTrasnfer>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TransferDetails;
