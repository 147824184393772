import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getData } from "../api/data/inicioData";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { useAuth } from "user-context-fran-dev";
import GenericUserMenu from "../components/GenericUserMenu";
import { APPNAME } from "../utils/constants";
/* import Footer from "../components/Footer"; */
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { replace } from "formik";
import MetaData from "../components/landing/metaData";
import LandingMetaData from "../../src/pages/landing/landingMetaData";
import BasicPopover from "../../src/components/GenericPopover";
import { color } from "framer-motion";
import keskinube_blanco from "../../src/assets/keskinube_blanco.png";
import { BusinessSettings } from "../api/businessManages";
import { useLocation } from "react-router-dom";
import GenericPopoverResponsive from "../components/GenericPopoverResponsive";
import CloseIcon from "@mui/icons-material/Close";
import { GenericDrawer } from "../components/keskiCreator/genericDrawer";


//-------------------STYLES ------------------------------>
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    /* width: `calc(100% - ${drawerWidth}px)`, */
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
//-------------EXPORTABLE FUNCTIONS ---------------------------------------->
export const renderMenu = (menus) => (
  <>
    {menus.menu.map((data, index) => (
      <div key={index}>
        {!data?.notfinish && (
          <div key={data.text}>
            {!data.nestedMenu ? (
              <IconButton
                key={data.text}
                onClick={() => {
                  console.log("ESTE ES EL DATA URL" + data.url);
                  const navigate = useNavigate();
                  navigate(`/${data.url}`);
                }}
                color="inherit"
              >
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText primary={data.text} />
              </IconButton>
            ) : (
              <NestedList data={data} menus={menus} />
            )}
            {data.gohome && <Divider />}
          </div>
        )}
      </div>
    ))}
  </>
);

export const renderMenuList = (menus, id, navigate) => (
  <>
    {menus.menu.map((data, index) => (
      <div key={index}>
        {!data?.notfinish && (
          <div key={data.text}>
            {!data.nestedMenu ? (
              <ListItem
                button
                sx={{ cursor: "pointer" }}
                key={data.text}
                onClick={() => {
                  navigate(`/${data.url}`);
                }}
              >
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText primary={data.text} />
              </ListItem>
            ) : (
              <NestedList data={data} menus={menus} />
            )}
            {data.gohome && <Divider />}
          </div>
        )}
      </div>
    ))}
  </>
);

export default function MenuDrawer(props) {
  const location = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const isCellPhone = window.innerWidth < 0 ? true : false;
  const isLandingRoute = location.pathname.includes("landing"); // Verifica si la ruta actual incluye "landing" exclusiva para el keskicreator
  const [domain, setDomain] = useState("");
  const [openCreator, setOpenCreator] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [menus, setMenus] = React.useState(
    getData(parseInt(window.localStorage.getItem("menu")))
  );
  const [openDrawer1, setOpenDrawer1] = useState({
      top: false,
      top1: false,
      right: false,
      right1: false,
  });
  if (window.localStorage.getItem("menu")) {
    if (menus.id !== parseInt(window.localStorage.getItem("menu"))) {
      console.log(
        "menu",
        getData(parseInt(window.localStorage.getItem("menu")))
      );
      setMenus(getData(parseInt(window.localStorage.getItem("menu"))));
    }
  }

  //-----UseEffect------------------------------------------------------------->
  useEffect(() => {
    if (props.posDrawer == "false") {
      setOpen(false);
    }

    function upDateMenu() {
      setMenus(getData(parseInt(window.localStorage.getItem("menu"))));
    }
    if (menus) {
      if (menus.id !== parseInt(window.localStorage.getItem("menu"))) {
        upDateMenu();
      }
    } else {
      navigate("/home");
    }
    // console.log("/home", menus);

    if (menus.menu[3]?.nestedMenu && menus.menu[0].text !== "Keski IA") {
      setSelectedMenu(menus.menu[3].nestedMenu[1]?.text);
      navigate(menus.menu[3].nestedMenu[1]?.url);
    } else if (menus.menu[1].text === "Inicio") {
      setSelectedMenu(menus.menu[2].text);
      navigate(menus.menu[2].url);
    } else {
      setSelectedMenu(menus.menu[1].text);
    }
  }, [props.reload, id, menus]);

  useEffect(() => {
    getDomain();
    if (!isLandingRoute) {
      // Function to check the current window width
      const brakePoint = window.matchMedia("(max-width: 600px)");

      // Function to handle changes in the media query
      const handleMediaChange = (e) => {
        setIsMobile(e.matches); // `e.matches` is true if the screen is <= 600px
      };

      // Check initial state
      handleMediaChange(brakePoint);

      // Add listener for changes in the media query
      brakePoint.addEventListener("change", handleMediaChange);

      // Cleanup listener on unmount
      return () => {
        brakePoint.removeEventListener("change", handleMediaChange);
      };
    }
  }, []);
  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);
  //--------Handel Methods------------------------------------------------>
  const handleDrawer = () => {
    setOpen(!open);
  };
  const handleClosePhone = () => {
    if (isCellPhone && open) {
      setOpen(!open);
    }
  };
  const handleSelectMenu = (menuText) => {
    setSelectedMenu(menuText);
  };
  const handleDrawerCreator = () => {
    setOpenCreator(!openCreator);
  };

  const handleNavigation = (link) => {
    navigate(link);
  };

  const handleNavigateMyPage = () => {
    const completeDomain = domain.startsWith("http")
      ? domain
      : `https://${domain}`;

    window.open(completeDomain, "_blank");
  };

  //---------------GET METHODS-------------------------------------------->

  const getDomain = async () => {
    // Función para obtener el dominio
    let useBusiness = new BusinessSettings();
    let response = await useBusiness.getAllByIdAuth("1", logout);

    // Suponiendo que la respuesta tiene la propiedad domain
    if (response.data && response.data.domain) {
      setDomain(response.data.domain);
    }
  };

  const [openDrawer, setOpenDrawer] = React.useState({
    //drawer links
    top: false,
    //drawer categories
    top1: false,
    //cart preview
    right: false,
    //profile drawer
    right1: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    setOpenDrawer({ ...openDrawer, [anchor]: open });
  };

  const menuResponsive = (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      {menus.MetaData && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 2,
            paddingTop: 8,
            gap: 1,
            position: "relative",
          }}
        >
          <IconButton
            onClick={toggleDrawer("top", false)}
            sx={{ position: "absolute", top: 20, left: 1, }}
          >
            <CloseIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <GenericPopoverResponsive title="Personalizar">
            <LandingMetaData menu={2} view={"Estilo"} />
            <LandingMetaData menu={2} view={"Libreria"} />
          </GenericPopoverResponsive>
          <GenericPopoverResponsive title="Información">
            <LandingMetaData menu={2} view={"Información"} />
            <LandingMetaData menu={2} view={"Legal"} />
          </GenericPopoverResponsive>
        </Box>
      )}
      <Button
        variant="text"
        sx={{
          ml: "17px",
          color: "black",
          justifyContent: "flex-start",
          textTransform: "none",
        }}
        onClick={handleNavigateMyPage}
      >
        Ir a mi pagína
      </Button>
    </Box>
  );

  return (
    <>
      {menus && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
          >
            <Toolbar>
              {/* ESTE ES EL NAV BAR MORADO QUE TIENE TODO EN SI */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                sx={{ display: menus.id === 2 ? "none" : "" }}
              >
                <MenuIcon />
              </IconButton>
              <Box
                component="img"
                src={keskinube_blanco}
                alt={`Logo Keskinube`}
                onClick={() => handleNavigation("/home")}
                sx={{
                  height: "4vh",
                  width: "auto",
                  cursor: "pointer",
                  marginLeft: "20px",
                  maxWidth: "50%",
                }}
              />
              <div style={{ position: "fixed", right: "1%" }}>
                <Grid container>
                  <Grid>
                    <Box
                      sx={{
                        display: { xs: "flex" },
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {menus.MetaData && (
                        <>
                          <Box
                            sx={{
                              display: { xs: "none", md: "flex" }, // Solo visible en dispositivos más grandes
                            }}
                          >
                            <BasicPopover
                              title={"Personalizar"}
                              children={
                                <>
                                  <LandingMetaData menu={2} openDrawer1={openDrawer1} setOpenDrawer1={setOpenDrawer1} view={"Estilo"} />
                                  <LandingMetaData menu={2} view={"Libreria"} />
                                </>
                              }
                            />

                            <BasicPopover
                              title={"Información"}
                              children={
                                <>
                                  <LandingMetaData
                                    menu={2}
                                    view={"Información"}
                                  />
                                  <LandingMetaData menu={2} view={"Legal"} />
                                </>
                              }
                            />

                            <Button
                              variant="text"
                              sx={{ color: "white", textTransform: "none" }}
                              onClick={handleNavigateMyPage}
                            >
                              Ir a mi pagína
                            </Button>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>

                  <Grid>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() =>
                        window.open("https://keskinube.com/help", "_blank")
                      }
                      sx={{ color: "white" }}
                    >
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                    <GenericUserMenu
                      changeMode={props.changeMode}
                      mode={props.mode}
                    />{" "}
                  </Grid>
                  {isLandingRoute && (
                    <IconButton
                      aria-label="open drawer"
                      onClick={toggleDrawer("top", true)}
                      sx={{ color: "white", display: { md: "none" } }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )}
                </Grid>
              </div>
            </Toolbar>
          </AppBar>
          <Box component="nav">
            <GenericDrawer
              variant="temporary"
              open={openDrawer}
              toggleDrawer={toggleDrawer}
              anchor="top"
              drawerWidth="100%"
            >
              {menuResponsive}
            </GenericDrawer>
          </Box>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              display: menus.id === 2 ? "none" : "",
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader></DrawerHeader>
            <Divider />

            <List>
              {menus.menu.map((data, index) => (
                <div key={index}>
                  {!data?.notfinish && (
                    <div key={data.text}>
                      {!data.nestedMenu ? (
                        <ListItem
                          sx={{
                            pl: 3,
                            cursor: "pointer",
                            bgcolor:
                              selectedMenu === data.text ? "#e7e1ed" : "white",
                            color:
                              selectedMenu !== data.text ? "black" : "#60269E",
                          }}
                          button
                          key={data.text}
                          onClick={() => {
                            if (data.comeback) {
                              window.localStorage.setItem(
                                "menu",
                                data.comeback,
                                console.log(data.comeback)
                              );
                              navigate(data.url);
                              handleSelectMenu(data.text);

                              return;
                            }

                            if (data.url && id) {
                              navigate(`${data.url}/${id}`);
                              handleSelectMenu(data.text);

                              return;
                            }
                            if (data.url) {
                              navigate(data.url);
                              handleSelectMenu(data.text);
                              return;
                            }
                          }}
                        >
                          <ListItemIcon>
                            {selectedMenu === data.text && data.icon
                              ? React.cloneElement(data.icon, {
                                sx: { color: "#60269E" },
                              })
                              : data.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              selectedMenu === data.text ? (
                                <b>{data.text}</b>
                              ) : (
                                data.text
                              )
                            }
                          />
                        </ListItem>
                      ) : (
                        <NestedList
                          data={data}
                          menus={menus}
                          selected={selectedMenu} // Pasa el selectedMenu
                          onSelect={handleSelectMenu}
                        />
                      )}
                      {data.gohome && <Divider />}
                    </div>
                  )}
                </div>
              ))}
            </List>
          </Drawer>
          <Main
            onClick={handleClosePhone}
            open={isCellPhone ? false : open}
            sx={{
              padding: 0,
            }}
          >
            <DrawerHeader />
            <Outlet />
          </Main>
        </Box>
      )}
    </>
  );
}

const NestedList = ({ data, selected, onSelect }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [dnMenu, setDnMenu] = useState("");

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (data.hasOwnProperty("dynamicMenu")) {
      setDnMenu(data.dynamicMenu(logout));
      setOpen(false);
    }
    //console.log("nested aqui bitcjhshshsh !!!!!!!!", data.nestedMenu[0].text);
  }, []);

  if (data.hasOwnProperty("dynamicMenu")) {
    return (
      <>
        {dnMenu && (
          <>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>{data.icon} </ListItemIcon>
              <ListItemText primary={data.text} />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {dnMenu.map((item, index) => (
                  <ListItem
                    key={index}
                    button
                    sx={{
                      pl: 4,
                      cursor: "pointer",
                      bgcolor: selected === item.text ? "#e7e1ed" : "white",

                      color: selected !== item.text ? "black" : "#60269E",
                    }}
                    onClick={() => {
                      navigate(`/landing/${item.url}`);
                      onSelect(item.text);
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        <ListItem sx={{ cursor: "pointer" }} button onClick={handleClick}>
          <ListItemIcon>{data.icon} </ListItemIcon>
          <ListItemText primary={data.text} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data.nestedMenu.map((item, index) => (
              <ListItem
                key={index}
                button
                sx={{
                  pl: 4,
                  cursor: "pointer",
                  bgcolor: selected === item.text ? "#e7e1ed" : "white",
                  color: selected !== item.text ? "black" : "#60269E",
                }}
                onClick={() => {
                  navigate(item.url);
                  onSelect(item.text); // Llama a la función para actualizar la selección
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    selected === item.text ? <b>{item.text}</b> : item.text
                  }
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  }
};
