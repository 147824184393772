import React, { useState, useEffect } from "react";
import GenericTable from "../../components/GenericTable";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Container from "../../layout/gridContainer";
import ClientTable from "./customer/customerTable";
import AddCustomer from "./customer/addCustomer";
import CustomerService from "../../api/customerService";
import { useAuth } from "user-context-fran-dev";
import { PaginationGeneric } from "react-generic-pagination";
import { useNavigate } from "react-router-dom";
import {
  GenericEmptyTable,
  GenericSkeletonTable,
} from "../../components/GenericEmptyTable";
import CircularProgress from "@mui/material/CircularProgress";
import GenericSearch from "../../components/GenericSearch/GenericSearch";

const InventoryCustomer = (props) => {
  const [openModal, setOpenModal] = useState();
  const [category, setCategory] = useState("");
  const { logout } = useAuth();
  const [table, setTable] = useState(null);
  const [page, setPage] = useState(1); //primer request en page 1
  const [totalpages, setTotalPages] = useState("");
  const [loading, setLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState({});

  const totalPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`page=${page}`);
    UpdateSupplierList();
  }, [page]);

  const UpdateSupplierList = () => {
    (async () => {
      setLoading(true);
      let irequestFilter = [];
      irequestFilter.push({ key: "page", value: `${page}` });
      irequestFilter.push({ key: "items", value: `${totalPerPage}` });
      let getCustomers = new CustomerService();
      let response = await getCustomers.filter(irequestFilter, logout);
      setTotalPages(response.data.pagination);
      setTable(response.data.data);
      setLoading(false);
    })();
  };

  const pageSetter = (page) => {
    setPage(page);
    navigate(`page=${page}`);
  };

  return (
    <Container >

      <Grid container display={'flex'} alignItems="center" justifyContent={"space-between"} m={'24px 0'}>

        <Typography
          variant="h5"
          component="div"
        >
          <strong>Clientes</strong>
        </Typography>
        <Grid item xs={2}>
          <Button
            onClick={() => setOpenDrawer({ right: true })}
            fullWidth
            variant="contained"
            sx={{ borderRadius: 60 }}
          >
            {" "}
            Agregar Cliente{" "}
          </Button>
          <AddCustomer
            open={openDrawer}
            toggleDrawer={() => { }}
            close={() => setOpenDrawer({ right: false })}
            UpdateSupplierList={UpdateSupplierList}
          />
        </Grid>
      </Grid>

      <GenericSearch
        setLoading={setLoading}
        updateInfo={UpdateSupplierList}
        Service={CustomerService}
        setData={setTable}
        label={"Buscar por Nombre"}
      />

      <Grid item xs={12}>
        <>
          <GenericTable titles={["Compañia", "Contacto", "Acciones"]}>
            {loading && <GenericSkeletonTable colums={3} />}
            {!loading && !table && <GenericEmptyTable />}
            {!loading &&
              table &&
              table.map((data, index) => (
                <ClientTable key={index} data={data} />
              ))}
          </GenericTable>
          <div className="margin">
            <PaginationGeneric
              className="paginationStyle"
              pagina={1}
              total={totalpages}
              pageSetter={pageSetter}
            ></PaginationGeneric>
          </div>
        </>
      </Grid>
    </Container>
  );
};

export default InventoryCustomer;
