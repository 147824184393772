import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Paper,
  styled,
} from "@mui/material";
import EditorConvertToHTML from "../../../../components/RichText";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  initValDataArticle,
  valSchDataArticle,
} from "../../../../utils/formHelper";
import { LoadingButton } from "@mui/lab";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  margin: "0 0 24px",
  width: "100%",
}));

export function ProductInfo({ article, SetProductInfo, load }) {
  const [loading, setLoading] = useState(false);
  const [modified, setModified] = useState(false);
  const [description, setDescription] = useState(null);


  useEffect(() => {
    if (article?.description) {
      setDescription(article.description);
    } else if (article?.description === null) {
      setDescription("");
    }
  }, [article]);

  const handleOnClickDescription = (text) => {
    setDescription(text);
  };

  const formik = useFormik({
    initialValues: initValDataArticle(article),
    enableReinitialize: true,
    validationSchema: Yup.object(valSchDataArticle()),
    onSubmit: async (formData) => {
      setLoading(true);
      formik.values.description = description;
      try {
        await SetProductInfo(formData);
      } catch (error) {
        console.error("Error al guardar los datos:", error);
      } finally {
        setModified(false);
        setLoading(false);
      }
    },
  });

  return (
    <Item elevation={4} sx={{ p: "16px", marginBottom: 2 }}>
      <form onSubmit={formik.handleSubmit} onChange={() => {
        setModified(true);
      }}>
        {article && (
          <>
            {["name", "sku"].map((val) => (
              <>
                <TextField
                  fullWidth
                  id={val}
                  name={val}
                  value={formik.values[val]}
                  onChange={formik.handleChange}
                  error={formik.touched[val] && Boolean(formik.errors[val])}
                  label={val === "sku" ? "SKU" : "Nombre"}
                  size="small"
                  margin="normal"
                  helperText={formik.touched[val] ? formik.errors[val] : ""}
                />
              </>
            ))}

            <Paper
              elevation={2}
              sx={{ borderRadius: "10px 10px 0 0", mt: "24px" }}
            >
              <Typography variant="h6" fontSize={"18px"} p={"8px "}>
                Descripción
              </Typography>
            </Paper>
            <Paper
              elevation={2}
              sx={{
                backgroundColor: "#fff",
                height: "200px",
              }}
            >
              {description !== null && (
                <EditorConvertToHTML
                  handleClick={handleOnClickDescription}
                  data={description}
                />
              )}
            </Paper>
            <Grid display={"flex"} justifyContent="flex-end" mt={"8px"}>
              <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={loading}
                  sx={{ borderRadius: 60 }}
                  disabled={!modified}
              >
                Guardar
              </LoadingButton>
            </Grid>
          </>
        )}
      </form>
    </Item>
  );
}